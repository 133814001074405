<template>
	<div :class="themeClass" class="AccountPay-Box padding-lr-13 margin-t-20">

		<div class="title font-size18 font-color-333 font-weight700">
			账服通钱包
		</div>
		<div v-if="AccountPayWalletInfo == null" class="position-relative flex-column-center-center font-size14">
			<div class="flex-row-center-center width-fill openACcount">
				<svg-icon icon-class="NoRightsAndInterests" />
			</div>
			<div class="text-center position-absolute" style="bottom:-70px">
				<div class="font-color-999 margin-t-5">亲，您还没有开通账服通钱包服务，去开通体验吧！</div>
				<div class="font-color-999 margin-t-5">账服通钱包是购买非自营店商品时唯一可用的货币资金付款方式。</div>
				<div class="open text-center background-color-theme pointer" @click="openAccountPay('开通账服通')">开通账服通钱包</div>
			</div>
		</div>
		<div v-else class="flex-row width-fill">
			<div class="flex-row" style="width: 100%; justify-content: space-between;position: relative;align-items: center;">
				<div class="carsd font-color-fff flex-column-align-center">
					
					<div class="font-size15 font-weight700 margin-t-25"  >
						{{getUserInfo.customerName}}
						<div class="fonrc pointer" v-if="getUserInfo.fCommerceTypeID==2&&B2bAccount!=null" @click="goValidate">未激活</div>
				    </div>
					<div class="font-size12 margin-t-10" >当前余额</div>
					<div class="font-size22 font-weight700" >¥ {{AccountPayWalletInfo.fEndBalance | NumFormat}}</div>
					<div class="margin-t-17 flex-row">
						<div class='button-box-TX flex-row-center-center margin-r-10 pointer' @click="toWithdrawal()">
							提现
						</div>
						<div class='button-box-CZ flex-row-center-center pointer' @click="toRecharge()">
							充值
						</div>
					</div>
				</div>
				<div class="rightCard font-color-fff"  style="position: relative;">
					<!-- <div class="aaaaa" style="position: absolute;top: 0;left:0;transform: scaleX(-1);"></div> -->
					<!--左侧logo图-->
					<div style="z-index: 999;margin-left: 31px;">
						<img style="width: 120px;height: 109px;" src="../../assets/u442.png"></img>
					</div>
					<!--右侧提示语-->
					<div class="rightCardSize" style="z-index: 999;color:#1e1e1e">
						<div style=" color:#E91D2C;font-size: 20px;font-weight: 700;letter-spacing: 2px;">温馨提示：</div>
						<div style=" color:#6D6D6D;font-size: 16px;letter-spacing: 2px;font-weight: 500; ">账服通钱包仅用于入驻店<br/>
						<div style="margin-top: -4px !important;">铺商品下单支付使用</div></div>
					</div>
				</div>
			</div>
		</div>
		<div class="seleAcuto" v-if="getUserInfo.fCommerceTypeID==1&&AccountPayWalletInfo != null && bankList.length>0">
			<span>账服通已绑{{bankList.length}}张银行卡</span>
			<span @click="tabto" class="spans">查看银行卡 > </span>
		</div>
		<div class="seleAcuto" v-if="getUserInfo.fCommerceTypeID==1&&AccountPayWalletInfo != null && bankList.length == 0">
			<span>账服通未绑卡</span>
			<span @click="openAccountPay('开通账服通')" class="spans">前去绑定</span>
		</div>
		<div class="seleAcuto" v-if="getUserInfo.fCommerceTypeID==2&&AccountPayWalletInfo != null &&withdraw.length>0">
			<span>账服通已绑{{withdraw.length}}银行账户</span>
			<span @click="tabto" class="spans">查看银行账户 > </span>
		</div>
		<div class="seleAcuto" v-if="getUserInfo.fCommerceTypeID==2&&AccountPayWalletInfo != null &&withdraw.length==0&&B2bAccount!=null">
			<span>账服通已绑银行账户</span>
			<span @click="tabto" class="spans">查看银行账户 > </span>
		</div>
		<div class="seleAcuto" v-if="getUserInfo.fCommerceTypeID==2&&AccountPayWalletInfo != null &&withdraw.length==0&&B2bAccount==null">
			<span>账服通未绑银行账户</span>
			<span @click="openAccountPay('开通账服通')" class="spans">前去绑定 > </span>
		</div>
		
		<!-- 时间状态筛选 -->
		<div v-if="AccountPayWalletInfo != null">
			<el-select v-model="checkedDate" placeholder="时间选择" clearable @change="dateChange" style="margin-top: 15px;" @clear="clearDate"	>
			    <el-option
			      v-for="item in TimeArr"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
			<el-select v-if="tabSelect==0" v-model="fPlyingStatus" clearable placeholder="充值状态" @change="handleCurrentChange(1)" @clear="handleCurrentChange(1)" style="margin-top: 15px; margin-left: 15px;"	>
			    <el-option
			      v-for="item in fPlyingStatusList"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
			<el-select v-if="tabSelect===1" v-model="fStatus" clearable placeholder="提现状态" @change="handleCurrentChanget(1)" @clear="handleCurrentChanget(1)" style="margin-top: 15px; margin-left: 15px;"	>
			    <el-option
			      v-for="item in fStatusList"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
		</div>
		
		<!-- 时间状态筛选结束 -->
		
		<div v-if="AccountPayWalletInfo != null">
			<div class="tab text-center margin-t-10 flex-row-space-between">
				<div class="flex-row">
					<div class="tab-item" :class="
				tabSelect == 0
				? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2'
				: ''
			"
					 @click="tabClick(0)">
						充值明细
					</div>
					<div class="tab-item" :class="
				tabSelect == 1
				? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2'
				: ''
			"
					 @click="tabClick(1)">
						提现明细
					</div>
					<div v-if='fIsShowReceipts ==1'   class="tab-item" :class="
				tabSelect == 2
				? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2'
				: ''
			"
					 @click="tabClick(2)">
						收入明细
					</div>
					<div class="tab-item" :class="
				tabSelect == 3
				? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2'
				: ''
			"
					 @click="tabClick(3)">
						支出明细
					</div>
				</div>
				<!-- <div class="mycard background-color-theme font-color-fff" @click="toMyBankCard">我的银行卡</div> -->
			</div>
			<div class="bill" v-if="tabSelect == 0">
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in cashdetails" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							账服通钱包充值(单号:&nbsp; <span :class='item.fPlatTxSn == ""?"":"activeClass"' @click='openlikone(item.fPlatTxSn)'>{{ item.fBillNumber }}</span>)
							<span :class="item.fPlyingStatusName === '支付成功' ? 'successBgc' : item.fPlyingStatusName === '支付失败' ? 'errorBgc' : 'bg-FF9900' " class="audit text-center margin-l-10 bg-FF9900 font-color-fff font-size12">{{ item.fPlyingStatusName }}</span>
						</div>
						<div class="font-size14">¥{{ item.fTotalMoney }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div class="font-color-999 font-size12">{{ item.fCreateTime }}</div>
						<!-- <div class="font-color-999 font-size12">{{item.fBalanceID}}</div> -->
					</div>
				</div>

				<div class="pagination flex-row-center-flex-end ">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxResultCount" :total="total"
					 @current-change="handleCurrentChange"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="cashdetails.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>

			</div>
			<div class="bill" v-if="tabSelect == 1">
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in detailData" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							账服通钱包提现(单号:&nbsp;<span :class='item.fPlatTxSn == ""?"":"activeClass"' @click='openliktwo(item.fPlatTxSn)'>{{ item.fRefundBillNumber }}</span>)
							<span :class="item.fStatusName === '提现成功' ? 'successBgc' : item.fStatusName === '提现失败' ? 'errorBgc' : 'bg-FF9900'" class="audit text-center margin-l-10 bg-FF9900 font-color-fff font-size12">{{ item.fStatusName }}</span>
						</div>
						<div class="font-size14">¥{{ item.fRefundMoney | NumFormat }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12">
								{{ item.fShopName
				}}<span class="font-color-999 font-size12">{{
					item.fCreateTime
				}}</span>
							</div>
						</div>

					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="total,prev, pager, next" :page-size="pagesizet" :total="totalt" @current-change="handleCurrentChanget"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="detailData.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>
			</div>
			<div class="bill" v-if="tabSelect == 2 && fIsShowReceipts == 1">
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in detailreceipts" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							{{item.fRemark}}(单号:&nbsp;<span :class='item.fPlatTxSn == ""?"":"activeClass"' @click='openlikthree(item.fPlatTxSn)'>{{ item.fSourceBillNumber }}</span>)
						</div>
						<div class="font-size14">¥{{ item.fReceiptsMoney | NumFormat }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12">
								<span class="font-color-999 font-size12">{{item.fCreateTime}}</span>
							</div>
						</div>
			
					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxrec" :total="rectotal" @current-change="handleCurrentChangerec"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="detailreceipts.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>
			</div>
			<div class="bill" v-if="tabSelect == 3">
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in detailpay" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							{{item.fRemark}}(单号:&nbsp;<span :class='item.fPlatTxSn == ""?"":"activeClass"' @click='openlikfour(item.fPlatTxSn)'>{{ item.fSourceBillNumber }}</span>)
						</div>
						<div class="font-size14">¥{{ item.fPayMoney | NumFormat }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12">
								<span class="font-color-999 font-size12">{{item.fCreateTime}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxpay" :total="paytotal" @current-change="handleCurrentChangepay"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="detailpay.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>
			</div>
		</div>
		<el-dialog  title="激活提现账户" :visible.sync="dialogFormVisible" width="20%" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="激活金额" :label-width="formLabelWidth" prop="money">
					<el-input v-model="ruleForm.money" placeholder="请输入来账金额激活" autocomplete="off" :required="true"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="validateCancel('ruleForm')">取 消</el-button>
				<el-button type="primary" @click="validateConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from "@/components/pagination/pagination.vue";
	export default {
		components: {

			pagination
		},
		data() {
			var checkMoney = (rule, value, callback) => {
				if (!/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
					callback(new Error("金额必须为数字值且最多保留两位小数"));
				} else {
					callback();
				}
			};
			return {
				bankList: [],
				fIsShowReceipts:'',//判断要不要显示 收入明细表
				currentPaget: 1, //当前页
				pagrec:1,//收入明细当前页
				pagpay:1,//支出明细当前页
				pagesizet: 6, //每页显示10条数据
				totalt: 0, //总数据
				rectotal:0,//收入明细总数据
				paytotal:0,//支出明细的总数据
				withdraw: [],
				rechargeACout: [],
				loading: true,
				cashdetails: [],
				cashList: {},
				detailData: [],
				detailreceipts:[],//收入明细数据
				detailpay:[],//支出明细数据
				tabID: 0,
				maxResultCount: 6, //每页显示条数
				maxrec: 6, //每页显示条数
				maxpay:6,//每页显示条数
				page: 1, //页数
				total: 0,
				tabSelect: 0,
				fEnableDelete: "",
				userinfo: {}, //用户信息
				AccountPayWalletInfo: null, //账服通钱包信息
				acsBankCard: {}, //账服通快捷支付银行卡信息
				acsWithdrawBankCard: {}, //账服通提现卡信息
				dialogFormVisible: false,
				ruleForm: {
					money: null,
				},
				rules: {
					money: [{
						validator: checkMoney,
						trigger: "blur"
					}],
				},
				formLabelWidth: "80px",
				B2bAccount:null,
				fAccountID:'',
				
				TimeArr: [], // 时间搜索框
				checkedDate: '',
				fBeginDate:"",
				fEndDate: "",
				fPlyingStatusList: [ // 充值状态
					{
						value:0,
						label: "未支付",
					},
					{
						value:1,
						label: "支付中",
					},
					{
						value:2,
						label: "支付成功",
					},
					{
						value:3,
						label: "支付失败",
					},
				],
				fPlyingStatus: '',
				fStatusList: [ // 提现状态
					{
						value:0,
						label: "审核中",
					},
					{
						value:1,
						label: "提现中",
					},
					{
						value:2,
						label: "提现成功",
					},
					{
						value:3,
						label: "提现失败",
					},
				],
				fStatus: ''
				
			};
		},
		computed: {
			current: {
				get() {
					return this.currentPage;
				},
				set() {},
			},
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},

		// 监听页面滚动条上拉至底部事件

		mounted() {
			//今年的第一天和最后一天
			let year1 = new Date().getFullYear();
			let yearFirstday1 = new Date(year1, 0, 1);
			let yearFirstday1End = new Date(year1, 11, 31);
			//去年的第一天和最后一天
			let year2 = new Date().getFullYear() -1;
			let yearFirstday2 = new Date(year2, 0, 1);
			let yearFirstday2End = new Date(year2, 11, 31);
			//前年的第一天和最后一天
			let year3 = new Date().getFullYear() -2;
			let yearFirstday3 = new Date(year3, 0, 1);
			let yearFirstday3End = new Date(year3, 11, 31);
			//获取前年之前所有的年份时间
			let yearFirstday4End = new Date(year3-1, 11, 31);
			let newarray = [] //时间选择的数组
			for(let i=0;i<5;i++){
				if(i==0){
					newarray.push({
						value:0,
						label: "近3个月",
						fBeginDate:new Date()- 3600 * 1000 * 24 * 90,
						fEndDate:new Date()
					})
				}else if(i==1){
					newarray.push({
						value: 1,
						label: "今年",
						fBeginDate:yearFirstday1,
						fEndDate:yearFirstday1End
					})
				}else if(i==2){
					newarray.push({
						value: 2,
						label: (new Date().getFullYear()-1)  + "年",
						fBeginDate:yearFirstday2,
						fEndDate:yearFirstday2End
					})
				}else if(i==3){
					newarray.push({
						value:3,
						label: (new Date().getFullYear()-2)  + "年",
						fBeginDate:yearFirstday3,
						fEndDate:yearFirstday3End
					})
				}else if(i==4){
					newarray.push({
						value:4,
						label: (new Date().getFullYear()-2)  + "年之前",
						fBeginDate:"",
						fEndDate:yearFirstday4End
					})
				}
			}
			this.TimeArr = JSON.parse(JSON.stringify(newarray))
			// this.getCashList();
			this.getRechargeDetails();
			this.getWithdrawalDetails();
			this.getBaseinfo();
			this.fIsShowReceipts = this.getUserInfo.fIsShowReceipts
			console.log('*****************',this.fIsShowReceipts)
		},
		methods: {
			// 时间筛选
			dateChange(val) {
				if (val === '') {
					return
				} else {
					for(let item of this.TimeArr){
						if(item.value == val){
							this.fBeginDate = item.fBeginDate
							this.fEndDate =   item.fEndDate
							if (this.tabSelect == 1) {
								this.handleCurrentChanget(1)
							} else if (this.tabSelect == 0) {
								this.handleCurrentChange(1)
							}
						}
					}
				}
			},
			clearDate() {
				this.fBeginDate = ''
				this.fEndDate = ''
				if (this.tabSelect == 1) {
					this.handleCurrentChanget(1)
				} else if (this.tabSelect == 0) {
					this.handleCurrentChange(1)()
				}
			},
			//充值明细里面的跳转
			openlikone(e){
				if(e != ''){
					// this.ApiRequestPost(
					// 	"api/mall/payment/t2911-request/get", {
					// 		fPlatTxSn: e,
					// 	}
					// ).then(
					// 	(result) => {
					// 		if(result.content == ''){
					// 			this.$message.info('未查询到当前账单信息')
					// 		}
					// 	},
					// 	(rej) => {}
					// );
				}else{
					console.log('不需要跳转')
				}
			},
			//提现页面的跳转链接
			openliktwo(e){
				if(e != ''){
					// this.ApiRequestPost(
					// 	"api/mall/payment/t2911-request/get", {
					// 		fPlatTxSn: e,
					// 	}
					// ).then(
					// 	(result) => {
					// 		if(result.content == ''){
					// 			this.$message.info('未查询到当前账单信息')
					// 		}
					// 	},
					// 	(rej) => {}
					// );
				}else{
					console.log('不需要跳转')
				}
			},
			//收入明细点击跳转链接
			openlikthree(e){
				if(e != ''){
					// this.ApiRequestPost(
					// 	"api/mall/payment/t2911-request/get", {
					// 		fPlatTxSn: e,
					// 	}
					// ).then(
					// 	(result) => {
					// 		if(result.content == ''){
					// 			this.$message.info('未查询到当前账单信息')
					// 		}
					// 	},
					// 	(rej) => {}
					// );
				}else{
					console.log('不需要跳转')
				}
			},
			//支出明细跳转链接
			openlikfour(e){
				if(e != ''){
					// this.ApiRequestPost(
					// 	"api/mall/payment/t2911-request/get", {
					// 		fPlatTxSn: e,
					// 	}
					// ).then(
					// 	(result) => {
					// 		if(result.content == ''){
					// 			this.$message.info('未查询到当前账单信息')
					// 		}
					// 	},
					// 	(rej) => {}
					// );
				}else{
					console.log('不需要跳转')
				}
			},
			tabto() {
				this.$router.push({
					path: '/businessme/allAccount',
					query: {
						title: '账户通银行卡'
					}
				})
			},
			openAccountPay(val) {
				// fCommerceTypeID:1 个人   2 企业
				
				if (this.userinfo.fCommerceTypeID == 1) {
					this.$router.push({
						path: '/businessme/individualUsersBindingCard',
						query: {
							title: val,
							type: 2,
							fAccountname: '开通账服通',
							
						}
					}) //跳转个人用户绑卡
				} else if (this.userinfo.fCommerceTypeID == 2) {
					this.$router.push({
						path: '/businessme/enterpriseUsersBindingCard',
						query: {
							title: val,
							type: 2,
							fAccountname: '账服通'
						}
					}) //跳转企业用户绑卡
				}
			},
			// 企业用户去充值验证是否绑卡成功
			goValidate() {
				// this.dialogFormVisible = true;
				this.tabto();
			},
			// 验证弹框点击确定
			validateConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/validatie-acs-b2b-account", {
								fMoney: Number(this.ruleForm.money),
								fAccountID:this.fAccountID 
							}
						).then(
							(result) => {
								this.validateCancel(formName)
								this.getRechargeDetails();
								this.getWithdrawalDetails();
								this.getBaseinfo();
							},
							(rej) => {}
						);
					} else {
						return false;
					}
				});
			},
			// 验证弹框点击取消
			validateCancel(formName) {
				this.$refs[formName].resetFields();
				this.dialogFormVisible = false;
			},
			// 获取用户信息
			getBaseinfo() {
				this.ApiRequestPost(
					"api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full"
				).then(
					(result) => {
						console.log(result, "获取用户信息");
						if (result.obj != null) {
							this.userinfo = result.obj;
							console.log(this.userinfo);
							// 判断是不是开通了账服通,未开通返回obj等于null
							this.getAccountPayWalletInfo();
							this.getAcsPhysical();
							this.getwhiPhysical();
							this.isActivateAcsB2bAccount();
							// 查询账服通快捷支付银行卡信息,未开卡返回obj等于null
							// this.getAcsPhysicalAccount();
							// 查询账服通提现卡信息 个人用户有提现卡
							// this.getAcsWithdrawPhysicalAccount()
							// this.loading=false
						}
					},
					(rej) => {}
				);
			},
			//   获取账服通钱包余额
			getAccountPayWalletInfo() {
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-acs/get-my-ACS"
					// "api/mall/ebcustomer/bank-account/get-acs-virtual-account"
				).then(
					(result) => {
						if (result.obj == null || result.obj.fCustomerID == null) {
							this.AccountPayWalletInfo = null;
							return;
						}
						this.AccountPayWalletInfo = result.obj;
						console.log(this.AccountPayWalletInfo, "账服通钱包余额");
					},
					(rej) => {}
				);
			},

			//   账服通绑定提现银行卡查询
			getAcsWithdrawPhysicalAccount() {
				this.ApiRequestPost(
					"api/mall/ebcustomer/bank-account/get-acs-withdraw-physical-account"
				).then(
					(result) => {
						console.log(result.obj, "账服通绑定提现银行卡查询");
						this.acsWithdrawBankCard = result.obj;
					},
					(rej) => {}
				);
			},

			handleCurrentChange(val) {
				this.page = val;

				this.getRechargeDetails();
			},
			handleCurrentChanget(val) {
				this.currentPaget = val;

				this.getWithdrawalDetails();
			},
			//收入明细翻页点击
			handleCurrentChangerec(val){
				this.pagrec = val
				this.getReceipts()
			},
			//支出明细翻页点击
			handleCurrentChangepay(val){
				this.pagpay = val
				this.getReceipts()
			},
			// tab切换
			tabClick(val) {
				this.tabSelect = val;
				if (this.tabSelect == 0) {
					this.getRechargeDetails();
				}else if(this.tabSelect == 1){
					this.getWithdrawalDetails();
				}else if(this.tabSelect == 2){
					this.getReceipts();
				}else{
					this.getpayData();
				}
			},

			// 获取充值明细
			getRechargeDetails() {
				console.log(this.fPlyingStatus)
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/customer-payment-bill/get-customer-acs-charge", {
						fPlyingStatuss: this.fPlyingStatus === '' ? [] : [this.fPlyingStatus*1],
						beginTime : this.fBeginDate==""?"":this.getDate(this.fBeginDate),
						endTime: this.fEndDate==""?"":this.getDate(this.fEndDate),
						filter: "",
						maxResultCount: this.maxResultCount,
						skipCount: (this.page - 1) * this.maxResultCount,
					}
				).then(
					(result) => {
						console.log(result, "result111111");
						this.cashdetails = [];
						this.cashdetails = result.obj.items;
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
						}
						this.total = result.obj.totalCount

					},
					(rej) => {}
				);
			},
			// 获取提现明细
			getWithdrawalDetails() {
				console.log(this.fStatus)
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/refund-bill/get-list-current-customer-zfc-tx", {
						fStatuss: this.fStatus === '' ? [] : [this.fStatus*1],
						fBeginDate: this.fBeginDate==""?"":this.getDate(this.fBeginDate),
						fEndDate: this.fEndDate==""?"":this.getDate(this.fEndDate),
						maxResultCount: this.pagesizet,
						filter: "",
						skipCount: (this.currentPaget - 1) * this.pagesizet,
					}
				).then(
					(result) => {
						console.log(result, "result222222");
						this.detailData = [];
						this.detailData = result.obj.items;
						// if (result.obj.items.length > 0) {
						// 	this.fEnableDelete = result.obj.items[0].fEnableDelete;
						// }
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
						}
						this.totalt = result.obj.totalCount
					},
					(rej) => {}
				);
			},
			//获取收入明细
			getReceipts(){
				this.ApiRequestPostNOMess(
					"api/mall/ebbalance/customer-acs-detail/get-detail-by-receipts", {
						maxResultCount: this.maxrec,
						filter: "",
						skipCount: (this.pagrec - 1) * this.maxrec,
					}
				).then(
					(result) => {
						console.log(result, "result222222");
						this.detailreceipts = [];
						this.detailreceipts = result.obj.items;
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
						}
						this.rectotal = result.obj.totalCount
					},
					(rej) => {}
				);
			},
			//获取支出明细
			getpayData(){
				this.ApiRequestPostNOMess(
					"api/mall/ebbalance/customer-acs-detail/get-detail-by-pay", {
						maxResultCount: this.maxpay,
						filter: "",
						skipCount: (this.pagpay - 1) * this.maxpay,
					}
				).then(
					(result) => {
						console.log(result, "result222222");
						this.detailpay = [];
						this.detailpay = result.obj.items;
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
						}
						this.paytotal = result.obj.totalCount
					},
					(rej) => {}
				);
			},
			// 提现跳转
			toWithdrawal() {
				if (this.AccountPayWalletInfo.fEndBalance == 0) {
					this.tipsError("亲,您当前的余额为零哦~")
					return;
				}
				let obj = {
					type: "withdrawal",
					walletType: "ZFT",
				};
				this.$router.push({
					path: "/businessme/RechargeWallet",
					query: {
						data: encodeURIComponent(JSON.stringify(obj)),
					},
				});
			},
			
			//判断账服通是否可以进行绑定新卡
			isActivateAcsB2bAccount() {
				this.ApiRequestPostNOMess(
					"/api/mall/ebcustomer/bank-account/is-activate-acs-b2b-account"
				
				).then(
					(result) => {
						this.B2bAccount = null;
						this.B2bAccount = result.obj;
						if( result.obj){
							this.fAccountID = result.obj.fAccountID;
						}
						
					},
					(rej) => {}
				);
			},
			//获取卡片
			getAcsPhysical() {
				let url = "api/mall/ebcustomer/bank-account/get-acs-physical-recharge-account-list"
				this.ApiRequestPostNOMess(
					url
				).then(
					(result) => {
						this.rechargeACout = [];
						this.rechargeACout = result.obj;
						console.log(this.rechargeACout)
						this.rechargeACout.forEach(item => {
							this.bankList.push(item.fAccountNumber)
						})
						let set = new Set(this.bankList)
						this.bankList = [...set]
					},
					(rej) => {}
				);
			},
			//获取提现
			getwhiPhysical() {
				let url = "/api/mall/ebcustomer/bank-account/get-acs-physical-withdraw-account-list"
				this.ApiRequestPostNOMess(
					url
				).then(
					(result) => {
						this.withdraw = [];
						this.withdraw = result.obj;
						console.log(this.withdraw)
						this.withdraw.forEach(item => {
							this.bankList.push(item.fAccountNumber)
						})
						let set = new Set(this.bankList)
						this.bankList = [...set]
					},
					(rej) => {}
				);
			},
			// 充值跳转
			toRecharge() {
				if (this.getUserInfo.fCommerceTypeID == 1) {
					if (this.rechargeACout.length == 0) {
						this.tipsError("亲,您还未绑定充值卡哦~")
						return;
					}
				}

				let obj = {
					type: "recharge",
					walletType: "ZFT",
				};
				this.$router.push({
					path: "/businessme/RechargeWallet",
					query: {
						data: encodeURIComponent(JSON.stringify(obj)),
					},
				});
			},
			// 跳转我的银行卡
			// toMyBankCard() {
			//   this.$router.push({
			//     path: "/myBankCard",
			//   });
			// },
			//跳转账户明细
			// toAccountDetails() {
			// 	this.$u.route('/PersonalCenter/pages/CashWallet/AccountDetails')
			// },
			//跳转交易明细
			toTransactionDetails() {
				this.$u.route("/PersonalCenter/pages/CashWallet/TransactionDetails");
			},
		},
		filters: {
			//格式化银行账户
			BankCardFormat: function(value) {
				// value = value.toString();
				if (value && value.length > 8) {
					return (`${value.substring(0, 4)}` + "<span style='margin:0px 5px;letter-spacing:4.5px;font-weight:700'>" +
						`${"*".repeat(value.length - 8).replace(/(.{4})/g, `
						$1 `)}` + "</span>" + `${value.slice(-4)}`);
				}
				return value;
			},
			formatPhone: function(val) {
				return `${val.substring(0,3)}****${val.substring(val.length-4)}`
			}
		},
	};
</script>
<style lang="scss" scoped="scoped">
	.fonrc{
		font-weight: bold;
		color: #002bff;
		border-bottom: 1px solid #002bff;
		position: absolute;
		right: 15px;
		top: 10px;
	}
	.button-box-TX {
		width: 118px;
		height: 38px;
		border-radius: 2px;
		border: 1px solid #FFFFFF;
		user-select: none;
	}

	.button-box-CZ {
		width: 120px;
		height: 40px;
		border-radius: 2px;
		color: #4180dc;
		background-color: #FFFFFF;
		user-select: none;
	}

	.carsd {
		position: relative;
		flex:1;
		height: 175px;
	    border-radius: 5px 0px 0px 5px;
		box-sizing: border-box;
		overflow: hidden;
		background-image: url(../../assets/cfbg.png);
		background-size: 100% 100%;
	}

	::v-deep .el-dialog {
		width: 400px;
	}

	.seleAcuto {
		margin-top: 10px;
		border-width: 0px;
		padding: 20px;
		left: 0px;
		top: 0px;
		width: 859px;

		background: inherit;
		background-color: rgba(255, 233, 157, 1);
		border: none;
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.spans {
		cursor: pointer;
		float: right;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.margin-b-8 {
		margin-bottom: 8px;
	}

	.flex-row-Delete {
		width: 57px;
		height: 23px;
		border-radius: 10px;
		text-align: center;
		line-height: 23px;
		// margin-left:95px;
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.margin-b-5 {
		margin-bottom: 5px;
	}

	.margin-l-6 {
		margin-left: 6px;
	}

	.border-b-F2F2F2 {
		border-bottom: 1px solid #f2f2f2;
	}

	.border-t-f2f2f2 {
		border-top: 1px solid #f2f2f2;
	}

	.lh24 {
		height: 24px;
		line-height: 24px;
	}

	.AccountPay-Box {
		width: 100%;
		height: calc(100% - 10px);
		// background-color: #ff3040;
		background-color: #ffffff;
	}

	.title {
		overflow: hidden; //解决盒子塌陷
		margin: 8px 0;
		height: 20px;
		line-height: 20px;
	}

	.card {
		position: relative;
		width: 450px;
		height: 173px;
		background-color: #009eb0;
		// opacity: 0.8;
		box-sizing: border-box;

		// margin-bottom: 20rpx;
		.bg {
			position: absolute;
			width: 100%;
			height: 100%;
			// z-index: -1;
			// opacity: 0.3;
		}

		.shopName {
			height: 15px;
			line-height: 15px;
		}

		.money {
			// margin-top: 5px;
			margin-right: 6px;
			position: absolute;
			top: 25px;
		}

		.card-btn {
			width: 150px;
			margin-top: 6px;
			float: right;
			border: 1px solid #fff;
			position: absolute;
			bottom: 20px;
			left: 280px;
			cursor: pointer;

			.btn1,
			.btn2 {
				display: inline-block;
				width: 75px;
				height: 26px;
				line-height: 25px;
			}
		}
	}

	.tab {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background-color: #f8f9fe;

		.tab-item {
			width: 108px;
			height: 40px;
		}

		.mycard {
			float: right;
			width: 110px;
			height: 30px;
			line-height: 30px;
			margin: auto 13px;
		}
	}

	.bill {
		.bill-item {
			height: 65px;
			padding: 10px 13px;
			box-sizing: border-box;
		}
	}

	.audit {
		display: inline-block;
		width: 50px;
		height: 17px;
		line-height: 17px;
	}

	// .width33{
	// 	width: calc((100%-20px)/3);
	// }

	.bankcard {
		width: 450px;
		height: 173px;
		opacity: 1;
		background: #e1efff;
		overflow: hidden;
		margin-left: 10px;

		.card-top {
			width: 100%;
			height: 125px;
			opacity: 1;
			background: #f8f9fe;
			box-sizing: border-box;
			padding: 15px;
		}

		.bank {
			height: 19px;
			line-height: 19px;

			.bank-icon {
				width: 29px;
				height: 18px;
			}

			.bank-name {
				margin-left: 5px;
			}

			.card-type {
				width: 48px;
				height: 17px;
				line-height: 17px;
				margin-left: 7px;
				border-radius: 2px;
			}

			.bg-33b1c0 {
				background: #33b1c0;
			}
		}

		.cardID {
			height: 24px;
			// margin-top: 12px;
		}

		.userinfo {
			padding-left: 15px;
		}

		.h51 {
			height: 51px;
			line-height: 51px;
			cursor: pointer;
		}

		.h51:hover {
			color: #0173FE;
		}

		.unbundling {
			height: 16px;
			line-height: 16px;
		}

	}

	.border-fff {
		width: 100px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		color: #fff;
		border: 1px solid #fff;
		margin-top: 5px;
	}

	.open {
		width: 235px;
		height: 45px;
		line-height: 45px;
		margin-top: 23px;
		margin-left: 80px;
	}

	.openACcount {
		.svg-icon {
			width: 505px;
			height: 505px;
		}
	}

	.pagination {
		margin-top: 20px;
		float: right;
	}
	.rightCard{
		flex:1;
		height: 175px;
		// background-image: url(../../assets/cfbg.png);
		border-radius: 0px 5px 5px 0px;
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	.aaaaa{
		width: 100%;
		height: 100%;
		background-image: url(../../assets/cfbg.png);
		background-size: 100% 100%;
	}
	.rightCardSize{
		width: 203px;
		font-size: 14px;
		line-height: 24px;
		margin-left: 38px;
	}
	.activeClass{
		// cursor: pointer;
		// color:blue;
		// text-decoration:underline
	}
	.successBgc {
		background-color: #19c500;
	}
	.errorBgc {
		background-color: #ccc;
	}
	/deep/.el-input__icon {
		line-height: 0px;
	}
</style>
